import React, { Component } from 'react';
import $ from 'jquery';
import config from "../config";
import { gapi } from 'gapi-script';
import axios from 'axios';
import moment from 'moment';
import { ulid } from 'ulid'
import arrayMove from "./arrayMove";
import Recurring from "./Recurring";
import { CSVLink } from "react-csv";


export default class InstanceManager extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			instanceSetting:[],
			Instances:[],
			instanceId:'',
		}

    }
	
	componentDidMount() {
		//this.getInstances();
	}
	
	getValue=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		this.setState({[event.target.name]:event.target.value});

	}
	
	deleteInstance=(id)=>{
		let ApiUrl = config.mongoApiUrl;
		let url = ApiUrl+'delete-instance/'+id;
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			alert(response.data);
			this.props.getInstances();
			this.props.resetInstance();
		}).catch(error => {
			alert('error::'+ error);
		})
	}
	
	selectInstance=(event)=>{
		let value = event.target.value;
		let instances = this.props.defaultProps.instances;
		
		instances.map((val,i)=>{
			if(val.id == value){
				this.setState({instanceSetting:val,instanceId:val.id});
			}
		});	
    }
	
	addMdsSheets(){
		
		var count = 1;
		$('#mdsSheetSec .append-sec').each(function() {
			count++;
		});
		
		let newRowAdd = '<div id="appendRow'+count+'" class="row align-items-end append-sec">'+
			'<div class="col-md-6">'+
				'<input class="form-control mds-input mds-input-sheet" placeholder="Spreadsheet '+count+'" type="text" name="mdsSpreadsheetId'+count+'" />'+
			'</div>'+
			'<div class="col-md-6">'+
				'<input class="form-control temp-left-side temp-input mds-input-temp" placeholder="Temp SheetId for Spreadsheet '+count+'" type="text" name="mdsTempSheetId'+count+'" /> <button id="'+count+'" class="btn btn-delete-mds-sheet"><i class="fa fa-trash"></i></button>'+
			'</div>'+
		'</div>';
		$('#mdsSheetSec').append(newRowAdd);
    }
	
	/* storeMdsInMongoB=()=>{
		let spreadsheetId = this.props.mdsSpreadsheetId;
		let mdsSheets = this.props.mdsSheets;
		mdsSheets.map((sheetName,i)=>{
			gapi.client.sheets.spreadsheets.values
			.get({
				spreadsheetId: spreadsheetId,
				range:sheetName, 
			})
			.then(
				response => {
					const allData = response.result.values;
					if(allData.length > 0){
						let mdsGuid = allData[1][91];
						let version = allData[1][59] ? allData[1][59] : '1.01';
						let layout ={
							"MDSGuid": mdsGuid,
							"MDSName": sheetName,
							'Version': version,
							'Fields': allData,
						}
						
						this.storeMdsJsonMongo(JSON.stringify(layout));
					}
					
				},function(reason) {
					console.log(reason.result.error.message);
				}
			);
			return false;
		});		
    } */
	
	
	
	/* storeMdsInMongo=()=>{
		let spreadsheetId = this.props.mdsSpreadsheetId;
		let mdsSheets = this.props.mdsSheets;
		
		console.log('mdsSheets->',mdsSheets);
		return false;
		
		mdsSheets.map((sheetName,i)=>{
			gapi.client.sheets.spreadsheets.values
			.get({
				spreadsheetId: spreadsheetId,
				range:sheetName, 
			})
			.then(
				response => {
					const allData = response.result.values;
					if(allData.length > 0){
						let mdsGuid = allData[1][91];
						let version = allData[1][59] ? allData[1][59] : '1.01';
						let dates = [];
						allData.map((field,i)=>{
							if(i > 0){
								
								let date = new Date(field[98]);
								if(date != 'Invalid Date'){
									dates.push(date);
								}
							}
						});
						
						let maximumDate = new Date(Math.max.apply(null, dates));
						let minimumDate = new Date(Math.min.apply(null, dates));
						let modifiedDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
						let createdDate = new Date(minimumDate).toLocaleString("en-US").replace(',','');
					
						
						let layout ={
							"MDSGuid": mdsGuid,
							"MDSName": sheetName,
							"CreatedDate": moment(createdDate).format('YYYY-MM-DD HH:mm:ss'),
							"ModifiedDate": moment(modifiedDate).format('YYYY-MM-DD HH:mm:ss'),
							'Version': version,
							'Fields': allData,
						}
						
						this.storeMdsJsonMongo(JSON.stringify(layout));
					}
					
				},function(reason) {
					console.log(reason.result.error.message);
				}
			);
		});
		
    } */
	
	/* getInstances(){
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'/instances';
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			 const data = response.data;
			 this.setState({Instances:data});
		}).catch(error => {
			console.log('error::', error);
		})
	} */
	
	/* storeMdsJsonMongoV2(data){
		console.log('data', data);
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'store-mds-files-v2';
		axios({
			method: 'POST',
			url: url,
			data: data,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			console.log('response::', response.data);
		}).catch(error => {
			console.log('error::', error);
		}) 
	} */
	
	storeInstanceMongo=()=>{
		let newInstanceName = this.state.newInstanceName;
		let uid = ulid();
		if(newInstanceName){
			let mongoApiUrl = config.mongoApiUrl;
			let url = mongoApiUrl+'store-instance';
			let data ={
				"InstanceName": newInstanceName,
				"InstanceGuild": uid
			}
			axios({
				method: 'POST',
				url: url,
				data: JSON.stringify(data),
				headers: {
					'Content-Type': 'application/json',
				}
			})
			.then(response => {
				//alert(response.data);
				this.props.getInstances();
				console.log('response::', response.data);
			}).catch(error => {
				console.log('error::', error);
			})
		}else{
			alert('Please enter Instance name!');
		}
	}
	
	render() {
		const {defaultProps, mdsSheets} = this.props;
		//const {Instances} = this.state;
		let optionHtml = '';
		if(defaultProps.instances){
			let Instances = defaultProps.instances;
			optionHtml = Instances.map(function(val,i) {
				return (
					<option value={val.InstanceGuild} key={i}>{val.InstanceName}</option>
				);
			})
		}
		
		let FieldsCount = 0;

		return (
			<div className="instance-manager">
				<div className="mongo-instance">
					<div className="row">
					<div className="col-md-5 col-lg-5 col-12">
						<div className="inss-title"> MongoDB Instance Manager</div>
					</div>
					<div className="col-md-4 col-lg-4 col-10 pr-0">
						<select id="selectedInstance" className="form-control custom-select-ins" name="selectedInstance" value={defaultProps.selectedInstance} onChange={this.props.getValue}>
							{optionHtml}
						</select>
						
					</div>
					{defaultProps.selectedInstance ? 
					<div className="col-md-3 col-lg-3 col-3">
						<button type="button" className="btn btn-success mr-2" data-target="#newInstance" data-toggle="modal"><i  className="fa fa-plus"></i></button>
						<button type="button" className="btn btn-danger mr-2" onClick={() => { if (window.confirm('Are you sure you want to delete this Instance?')) this.deleteInstance(defaultProps.selectedInstance)} } ><i className="fa fa-trash"></i></button>
						
						<button type="button" className="btn btn-info" data-target="#uploadMDS" data-toggle="modal"><i  className="fa fa-upload"></i></button>
						
					</div>
					:null}
					</div>
				</div>
				
				<div className="setting-ins-section">
					{mdsSheets.length > 0 ?
					<div className="mds-file-list">
						<table className="table table-bordered">
							<thead>
							  <tr>
								<th>MDS Name</th>
								<th className="ins-th">Last Updated</th>
								<th>Ver.#</th>
							  </tr>
							</thead>
							<tbody>
							{mdsSheets.map((sheet, i) => {
								FieldsCount = FieldsCount+sheet.FieldsCount;
								return(<tr key={i}>
								<td>{sheet.MDSName} </td>
								<td>{sheet.ModifiedDate} </td>
								<td><i>{sheet.Version}</i></td>
								</tr>)
							})}
							</tbody>
						</table>
						<div className="tt-number">Total # of fields : {FieldsCount}</div>
					</div>
					:null}
				</div>
				
				<div className="modal" id={"newInstance"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
						  <div className="modal-header">
							<h4> New Instance name </h4>
								
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						  </div>
						  <div className="modal-body">
								<input id="newInstanceName" className="form-control" type="text" name='newInstanceName' onChange={this.getValue} />
						  </div>
						  <div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-primary float-left mr-2" onClick={this.storeInstanceMongo} data-dismiss="modal">Save</button>
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
								</div>
						  </div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"uploadMDS"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
						  <div className="modal-header">
							<h4> Upload MDS Forms from Google sheet</h4>
								
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						  </div>
						  <div className="modal-body">
								<input id="mdsSpreadsheetId" className="form-control" type="text" name='mdsSpreadsheetId' onChange={this.getValue} />
						  </div>
						  <div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-primary float-left mr-2" onClick={()=> this.props.mdsFromGoogleSheet(this.state.mdsSpreadsheetId)} data-dismiss="modal">Upload</button>
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
								</div>
						  </div>
						</div>
					</div>
				</div>
				
				
				
			</div>
		);	
	}	
}
